import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from '@emotion/styled'
import { Container, BlockHead, Spacer, LinkBase } from '../components/atoms'

import { SectionTitle, TextBase as Text, WorkCard } from '../components/blocks'
import { Box, Image } from 'rebass'
import { Flex } from 'theme-ui'


export const query = graphql`
  query CaseStudiesPageQuery {
    caseStudies: allSanityCaseStudy(
      filter: { slug: { current: { ne: null } } }
    ) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fixed(width: 400, height: 200) {
                width
                height
                src
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`

const headingStyle = {
  fontSize: [5],
  pb: 3,
}

const CaseStudiesPage = (props) => {
  const { data, errors } = props

  if (errors) throw errors

  const postEdges = (data && data.caseStudies && data.caseStudies.edges) || []

  return (
    <Layout>
      <SEO title={'Works'} description="Featured projects we have undertaken" />
      <Container>
        <Box mt={5} mb={5}>
          <Text variant="text.pageTitle" pb={5}>
            Featured Projects
          </Text>
          <Text variant="text.pageDesc">
            We are a strategic, creative studio based out of Bangalore. Our team
            works with founders and product teams to ideate, design and build
            quality digital products and brand experiences.
          </Text>
        </Box>

        {postEdges && (
          <Flex            
            sx={{
              // listStyle: 'none',
              flexWrap: ['wrap', 'wrap'],              
              flexDirection: ['column', 'row', 'row'],
              // display: 'grid',
              // gridGap: 3,
              // gridTemplateColumns: 'repeat(300px, minmax(300px, 1fr))',
            }}
          >
            {postEdges.map((post) => (
              <WorkCard post={post} />
            ))}
          </Flex>
        )}
      </Container>
    </Layout>
  )
}
export default CaseStudiesPage
